import axios from "axios";
import { notify, notifyError } from "./toastify";
// import { access } from "fs";
// import { error } from "console";

const api = "https://api.tryalps.com/api/v1";
// const api = "https://alps-qzms.onrender.com/api/v1";

// export function getCookie(cookieName: string) {
//   const name = cookieName + "=";
//   const decodedCookie = decodeURIComponent(document.cookie);
//   const cookieArray = decodedCookie.split(";");

//   for (let i = 0; i < cookieArray.length; i++) {
//     let cookie = cookieArray[i];
//     while (cookie.charAt(0) === " ") {
//       cookie = cookie.substring(1);
//     }
//     if (cookie.indexOf(name) === 0) {
//       return cookie.substring(name.length, cookie.length);
//     }
//   }
//   return null; // Return null if the cookie is not found
// }

export const setConfig = (accessToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  return config;
};

export const setImageConfig = (accessToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
    // withCredentials: true,
  };

  return config;
};

export const getUserProfile = async (domain) => {
  let result = [];
  await axios
    .get(`${api}/user/get-profile/${domain}`, {
      headers: {
        organization: domain,
      },
      // withCredentials: true,
    })
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(result);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getUserCategories = async (domain) => {
  let result = [];
  await axios
    .get(`${api}/category?status=published`, {
      headers: {
        organization: domain,
      },
      // withCredentials: true,
    })
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(result);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
        console.log(err);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getUserPlaylist = async (domain) => {
  let result = [];
  await axios
    .get(`${api}/playlist`, {
      headers: {
        organization: domain,
      },
      // withCredentials: true,
    })
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(result);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
        console.log(err);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getCategoryArticles = async (id, domain) => {
  let result = [];
  await axios
    .get(`${api}/article/category/${id}`, {
      headers: {
        organization: domain,
      },
      // withCredentials: true,
    })
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(result);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getAllArticles = async (domain) => {
  let result = [];
  console.log("getting all articles");
  await axios
    .get(`${api}/article/user`, {
      headers: {
        organization: domain,
      },
      // withCredentials: true,
    })
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(response);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
      console.log(err);
    });
  return result;
};

export const getPlaylistGuides = async (id, domain) => {
  let result = [];
  await axios
    .get(`${api}/guide/playlist/${id}`, {
      headers: {
        organization: domain,
      },
      // withCredentials: true,
    })
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(result);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getStatusArticles = async (status, domain) => {
  let result = [];
  await axios
    .get(`${api}/article/user?status=${status}`, {
      headers: {
        organization: domain,
      },
      // withCredentials: true,
    })
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(result);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
        console.log(err);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const upVoteArticle = async (articleId, domain) => {
  let result = [];
  await axios
    .patch(
      `${api}/article/${articleId}/upvote`,
      {},
      {
        headers: {
          organization: domain,
        },
        // withCredentials: true,
      }
    )
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(result);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
        console.log(err);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const downVoteArticle = async (articleId, domain) => {
  let result = [];
  await axios
    .patch(
      `${api}/article/${articleId}/downvote`,
      {},
      {
        headers: {
          organization: domain,
        },
        // withCredentials: true,
      }
    )
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(result);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
        console.log(err);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const submitRequest = async (body, domain) => {
  let result = [];
  await axios
    .post(`${api}/support`, body, {
      headers: {
        organization: domain,
        "Content-Type": "application/json",
      },
      // withCredentials: true,
    })
    .then((response) => {
      console.log(response);
      // if (response.data.status === "success") {
      //   result = response.data.data.data;
      //   console.log(result);
      // }
    })
    .catch((err) => {
      console.log(err);
      // if (err.response.data.message) {
      //   notifyError(err.response.data.message);
      //   console.log(err);
      // } else {
      //   notifyError("Network Error");
      // }
    });
  return result;
};
