import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import { ChevronRight } from "heroicons-react";
import { getCategoryArticles, getUserCategories } from "../services/request";
import { SpinnerCircular } from "spinners-react";
import { BookOpenOutline } from "heroicons-react";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
const CategoryPage = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const top = useRef(null);
  const [
    domainName,
    current,
    setCurrent,
    content,
    setContent,
    view,    
    guides,
    collections,
    searchQuery,
    setSearchQuery,
  ] = useOutletContext();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(window.innerWidth);
  const [category, setCategory] = useState("");
  const getLinkedArticles = async (categoryData) => {
    let data = await getCategoryArticles(
      categoryData.id,
      "tech_hub.tryalps.app"
    );
    let filteredData = data.filter((item) => item.status === "published");
    console.log(filteredData);
    setArticles(filteredData);
    setLoading(false);
  };

  const getCategory = async () => {
    scrollToRef(top);
    setLoading(true);
    let data = await getUserCategories(domainName);
    // let data = await getUserCategories("tech_hub.tryalps.app");
    if (data) {
      const categoryData = data.find(
        (item) => item.name === categoryId.replace(/-/g, " ")
      );
      console.log(categoryData);
      setCategory(categoryData);
      // setContent(categoryData)
      getLinkedArticles(categoryData);
    }
  };

  useEffect(() => {    
    getCategory();
  }, [categoryId]);

  useEffect(() => {
    function handleResize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);
  return (
    <>
      <div
        className="ml-[305px] md:ml-0 w-full cflexss gap-[32px] mb-[100px]"
        ref={top}
      >
        {category && (
          <>
            <div className="w-full py-[4px] flexsm text-[14px]">
              <p
                className="text-gray-500 cursor-pointer"
                onClick={() => {
                  navigate("/");
                }}
              >
                Collection
              </p>
              <ChevronRight size="20px" color="gray" />
              <p>{category.name}</p>
            </div>
            <h1 className="text-[40px] font-bold">{category.name}</h1>
            <div className="w-full cflexss gap-[25px] text-[14px]">
              <img
                src={category.coverURL}
                className="rounded-[8px] w-full h-[562px] md1:h-[276px] object-cover"
                alt="category-cover"
              />
              <div className="cflexss gap-[8px]">
                <p className="font-semibold text-[#A3A3A3]">
                  IN THIS COLLECTION
                </p>
                <p className="text-[18px]">{category.description}</p>
              </div>
            </div>
          </>
        )}
        <div className="w-full mt-[80px] cflexss gap-[24px]">
          <h1 className="font-bold text-[32px]">Articles</h1>
          {loading ? (
            <div className="flexmm w-full">
              <SpinnerCircular
                color="#000000"
                className="mr-4"
                secondaryColor={"#eeeeee"}
                size={50}
                thickness={150}
              />
            </div>
          ) : (
            <>
              {articles.map((item, index) => {
                return (
                  <>
                    <div
                      className="w-full flexsm gap-[32px] hover:bg-[#f5f5f5] rounded-[8px] cursor-pointer py-[4px] md1:mb-[20px] md1:px-[4px] md1:flex-wrap"
                      onClick={() => {
                        navigate(`/article/${item.title.replace(/ /g, "-")}`);
                      }}
                      key={item.id}
                    >
                      <img
                        src={item.coverURL}
                        alt="article-image"
                        className="rounded-[8px] min-w-[300px] max-w-[300px] h-[200px] object-cover md1:w-full md1:max-w-full"
                      />
                      <div
                        className="h-full cflexss gap-[8px] md1:w-full"
                        style={{
                          width: size <= 950 ? "100%" : "calc(100% - 300px)",
                        }}
                      >
                        <p className="font-bold text-[20px]">{item.title}</p>
                        <p className="text-[16px] line-clamp-5">
                          {item.description}
                        </p>
                        <div className="flexss gap-[8px] text-[14px] text-[#A3A3A3]">
                          <BookOpenOutline size="20px" />
                          <p>3 min read</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryPage;
