import React, { useEffect, useState } from "react";
import { Search } from "heroicons-react";
import SearchModal from "../components/SearchModal";

const HeroSection = ({ designInfo, searchQuery, setSearchQuery }) => {
  const [search, setSearch] = useState(false); // Set the state for the search modal

  return (
    <>
      {search && <SearchModal designInfo={designInfo} setSearch={setSearch} />}
      <div
        className="w-full h-[390px] pt-[50px] mt-[-40px] md:rounded-[10px] px-[112px] md:px-[20px] pb-[50px] cflexmm bg-mainColor font-inter"
        style={{
          width: "100%",
          backgroundImage: `url(${
            designInfo?.heroSectionBackground
              ? designInfo.heroSectionBackground
              : ""
          })`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          fontFamily: designInfo?.textFont,
        }}
      >
        <div className="w-full cflexmm gap-[40px]">
          <div
            className={`cflexmm w-full gap-[16px] text-center`}
            style={{ color: designInfo?.heroSectionTextColour }}
          >
            <h1 className="text-[52px] md:text-[35px] font-[700]">
              {designInfo?.heroSectionHeadline}
            </h1>
            <p className="text-[18px]">{designInfo?.heroSectionBodyCopy}</p>
          </div>
          <div className="w-full flexmm">
            <div
              className="pl-[24px] pr-[10px] py-[8px] flexsm w-[800px] h-[56px] rounded-full gap-[8px] bg-white flex-shrink"
              onClick={() => {
                setSearch(true);
              }}
            >
              <Search size="24px" color="#A3A3A3" className="cursor-pointer" />
              <input
                type="text"
                name="searchQuery"
                placeholder="Search for collections, guides, and more..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="h-[22px] outline-none border-none w-[90%]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
