import React from "react";
import HomePage from "./Pages/HomePage";
import CategoryPage from "./Pages/CategoryPage";
import GuidePage from "./Pages/GuidePage";
import ArticlePage from "./Pages/ArticlePage";
import RootLayout from "./components/RootLayout";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<RootLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/category/:categoryId" element={<CategoryPage />} />
        <Route path="/guide/:guideId" element={<GuidePage />} />
        <Route path="/article/:articleId" element={<ArticlePage />} />
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
