import React from "react";
import Default from "./Default";

const Body = ({ designInfo }) => {
  return (
    <>
      <Default                     
        designInfo={designInfo}       
      />
    </>
  );
};

export default Body;
