import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "heroicons-react";
import { useParams, useOutletContext } from "react-router-dom";
import { getPlaylistGuides, getUserPlaylist } from "../services/request";
import { SpinnerCircular } from "spinners-react";
import { VideoCameraOutline } from "heroicons-react";
import YouTube from "react-youtube";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
const GuidePage = () => {
  const navigate = useNavigate();
  const { guideId } = useParams();
  const top = useRef(null);
  const [
    domainName,
    current,
    setCurrent,
    content,
    setContent,
    view,
    articles,
    guides,
    collections,
    searchQuery,
    setSearchQuery,
  ] = useOutletContext();
  const [guideVideos, setGuideVideos] = useState([]);
  const [guide, setGuide] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(window.innerWidth);
  var getYouTubeID = require("get-youtube-id");
  const [url, setURL] = useState("");

  function formatDuration(duration) {
    if (duration) {
      const match = duration.match(/(\d+)M(\d+)S/);
      if (!match) return null;
      else {
        const minutes = parseInt(match[1]);
        const seconds = parseInt(match[2]);

        return `${minutes}:${seconds}`;
      }
    }
  }

  const getVideos = async (guideData) => {
    scrollToRef(top);
    let data = await getPlaylistGuides(guideData.id, domainName);
    // let data = await getPlaylistGuides(guideData.id, "tech_hub.tryalps.app");
    if (data) {
      setGuideVideos(data);
      if (!current) {
        setCurrent(data[0]);
        setURL(getYouTubeID(data[0].link));
      }
      setLoading(false);
    }
  };

  const getPlaylist = async () => {
    let data;
    setLoading(true);
    data = await getUserPlaylist(domainName);
    // data = await getUserPlaylist("tech_hub.tryalps.app");
    console.log(data);
    if (data) {
      let guideData = data.find(
        (item) => item.name === guideId.replace(/-/g, " ")
      );
      setGuide(guideData);
      getVideos(guideData);
    }
  };

  const opts = {
    height: size <= 1260 ? `${size / 2.5652}` : "572",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const onPlay = () => {
    // watchVideoRequest(course._id);
  };

  const onComplete = () => {
    // completeVideoRequest(course._id);
  };

  useEffect(() => {    
    getPlaylist();
  }, [guideId]);

  useEffect(() => {
    setURL(getYouTubeID(current.link));
  }, [current]);

  useEffect(() => {
    function handleResize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);
  return (
    <>
      <div
        className="ml-[305px] md:ml-0 w-full cflexss gap-[24px] mb-[100px]"
        ref={top}
      >
        <div className="w-full py-[4px] flexsm text-[14px]">
          <p
            className="text-gray-500 cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            Guides
          </p>
          <ChevronRight size="20px" color="gray" />
          <p>{guide.name}</p>
        </div>
        {loading ? (
          <div className="flexmm w-full">
            <SpinnerCircular
              color="#000000"
              className="mr-4"
              secondaryColor={"#eeeeee"}
              size={50}
              thickness={150}
            />
          </div>
        ) : (
          <>
            <div className="w-full cflexss gap-[25px] text-[14px]">
              {current && (
                <YouTube
                  videoId={url}
                  opts={opts}
                  // onReady={_onReady}
                  // onPlay={onPlay}
                  className={"rounded-[8px] w-full"}
                  // onEnd={onComplete}
                />
              )}
              <div className="flexss gap-[24px] w-full xl:flex-col-reverse">
                <div
                  className="cflexss gap-[25px]"
                  style={{
                    width: size <= 1260 ? "100%" : "calc(100% - 324px)",
                  }}
                >
                  <div className="cflexss gap-[12px]">
                    <div className="font-bold text-[12px] text-white bg-[#EA4335] rounded-[21px] px-[7px] py-[2px]">
                      <p>Now Playing</p>
                    </div>
                    <h1 className="font-bold text-[40px] sm:text-[30px]">
                      {current.title}
                    </h1>
                  </div>
                  <div className="cflexss gap-[8px] w-full">
                    <p className="font-semibold text-[#A3A3A3]">
                      IN THIS VIDEO
                    </p>
                    <p
                      className={`${
                        !seeMore && "line-clamp-[10]"
                      } text-[18px] sm:text-[14px] w-full`}
                      onClick={() => {
                        if (seeMore) {
                          setSeeMore(false);
                        }
                      }}
                    >
                      {current.summary}{" "}
                    </p>
                    {!seeMore && (
                      <span
                        className="font-bold text-gray-500 text-[18px] cursor-pointer"
                        onClick={() => {
                          setSeeMore(true);
                        }}
                      >
                        see more
                      </span>
                    )}
                  </div>
                </div>
                <div className="rounded-[5px] w-[300px] md1:w-full h-full border-[1px] gap-[10px] cflexss text-[14px]">
                  <div className="rounded-t-[5px] bg-[#EEEEEE] w-full flexsm px-[15px] py-[10px]">
                    <h1 className="font-semibold">UP NEXT</h1>
                  </div>
                  <div className="w-full cflexss max-h-[400px] overflow-y-auto">
                    {guideVideos?.map((item) => {
                      return (
                        <>
                          <div
                            className={`w-full flexsm gap-[12px] ${
                              current.id === item.id && "bg-[#EEEEEE]"
                            } hover:bg-[#EEEEEE] flexsm px-[15px] py-[10px] cursor-pointer`}
                            onClick={() => {
                              setCurrent(item);
                            }}
                          >
                            <img
                              src="/play-outline.svg"
                              alt="play-outline"
                              className="w-[16px] h-[16px]"
                            />
                            {item.title}
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <button
                    className={`outline-none border-none mb-[-3px] bg-[#2C2B2B] ${
                      guideVideos.indexOf(current) + 1 === guideVideos.length &&
                      "cursor-not-allowed"
                    } shadow-md w-full py-[10px] font-semibold text-[16px] text-white rounded-[10px]`}
                    onClick={() => {
                      if (
                        guideVideos.indexOf(current) + 1 <
                        guideVideos.length
                      ) {
                        setCurrent(
                          guideVideos[guideVideos.indexOf(current) + 1]
                        );
                      }
                    }}
                  >
                    <p>Next Video</p>
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full mt-[32px] pt-[56px] border-t-[2px] cflexss gap-[24px]">
              {guideVideos?.map((item, index) => {
                return (
                  <>
                    <div
                      className={`w-full flexsm gap-[32px] ${
                        current.id === item.id && "bg-[#ebeff5]"
                      } hover:bg-[#f5f5f5] rounded-[8px] py-[4px] md1:mb-[20px] md1:px-[4px] md1:flex-wrap`}
                      key={item.id}
                      onClick={() => {
                        setCurrent(item);
                        scrollToRef(top);
                      }}
                    >
                      <div className="relative min-w-[300px] max-w-[300px] h-[200px] md1:w-full md1:max-w-full flexmm cursor-pointer">
                        <img
                          src={item.thumbnail}
                          alt="article-image"
                          className="rounded-[8px] w-full h-full object-cover"
                        />
                        <div className="absolute mt-auto ml-auto flexmm w-[58px] h-[58px] rounded-full bg-[#000000]/90 border-[2px] border-white">
                          <img
                            src="/play.svg"
                            className="w-[20px]"
                            alt="play-logo"
                          />
                        </div>
                      </div>

                      <div
                        className="h-full cflexss gap-[8px] md1:w-full"
                        style={{
                          width: size <= 950 ? "100%" : "calc(100% - 300px)",
                        }}
                      >
                        <p className="font-bold text-[20px]">{item.title}</p>
                        <p className="text-[16px] line-clamp-5 w-full">
                          {item.summary}
                        </p>
                        <div className="flexss gap-[8px] text-[14px] text-[#A3A3A3]">
                          <VideoCameraOutline size="20px" />
                          <p>{`${formatDuration(item.duration)} watch`}</p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GuidePage;
