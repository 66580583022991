import React, { useRef, useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader2 from "./Loader2";
import { Search } from "heroicons-react";
import {
  getUserPlaylist,
  getAllArticles,
  getUserCategories,
} from "../services/request";

const SearchModal = ({ designInfo, setSearch }) => {
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("Everything");
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [playlist, setPlaylist] = useState([]);

  const [
    domainName,
    current,
    setCurrent,
    content,
    setContent,
    view,
    guides,
    collections,
  ] = useOutletContext();

  const getSearched = async (e) => {
    e?.preventDefault();
    setArticles([]);
    setCategories([]);
    setPlaylist([]);
    setLoading(true);
    let data1 = await getUserCategories(domainName);
    // let data1 = await getUserCategories("tech_hub.tryalps.app");
    if (data1) {
      setCategories(data1);
    }
    let data2 = await getUserPlaylist(domainName);
    // let data2 = await getUserPlaylist("tech_hub.tryalps.app");
    if (data2) {
      setPlaylist(data2);
    }
    let data3 = await getAllArticles(domainName);
    // let data3 = await getAllArticles("tech_hub.tryalps.app");
    if (data3) {
      setArticles(data3);
    }
    setLoading(false);
  };

  useEffect(() => {
    searchRef.current.focus();
    getSearched();
  }, []);

  const filteredArticles = articles?.filter(
    (item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      item.status === "published"
  );

  const filteredCategories = categories?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      item.status === "published"
  );

  const filteredPlaylist = playlist?.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      item.status === "published"
  );
  return (
    <>
      <div
        className="fixed z-[100] top-0 left-0 w-full h-[100vh] md:p-[20px] flexmm bg-secondary3 overflow-y-auto"
        onClick={() => {
          setSearch(false);
        }}
        style={{
          fontFamily: designInfo?.textFont,
        }}
      >
        <div
          className="cflexss gap-[10px] z-[150] w-[634px] flex-shrink rounded-[6px] bg-white shadow-xl"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="w-full flexbm gap-[13px] px-[20px] py-[12px] border-b-[1px] font-medium text-[14px] flex-wrap">
            <button
              className={`px-[12px] py-[6px] rounded-[10px] border-[1px] border-[#C6C6C6] ${
                searchType === "Everything" ? "text-black" : "text-[#C6C6C6]"
              } outline-none`}
              onClick={() => {
                setSearchType("Everything");
              }}
            >
              <p>Search Everything</p>
            </button>
            <button
              className={`px-[12px] py-[6px] rounded-[10px] border-[1px] border-[#C6C6C6] ${
                searchType === "Folders" ? "text-black" : "text-[#C6C6C6]"
              } outline-none`}
              onClick={() => {
                setSearchType("Folders");
              }}
            >
              <p>Search Folders</p>
            </button>
            <button
              className={`px-[12px] py-[6px] rounded-[10px] border-[1px] border-[#C6C6C6] ${
                searchType === "Articles" ? "text-black" : "text-[#C6C6C6]"
              } outline-none`}
              onClick={() => {
                setSearchType("Articles");
              }}
            >
              <p>Search Articles</p>
            </button>
            <button
              className={`px-[12px] py-[6px] rounded-[10px] border-[1px] border-[#C6C6C6] ${
                searchType === "Videos" ? "text-black" : "text-[#C6C6C6]"
              } outline-none`}
              onClick={() => {
                setSearchType("Videos");
              }}
            >
              <p>Search Videos</p>
            </button>
          </div>
          <form
            className="relative cflexss w-full px-[20px] gap-[38px]"
            onSubmit={getSearched}
          >
            <div className="cflexss w-full gap-auto overflow-y-auto min-h-[300px] max-h-[50vh] md:min-h-[200px] md:max-h-[35vh]">
              {filteredArticles.length === 0 &&
                filteredCategories.length === 0 &&
                filteredPlaylist.length === 0 &&
                searchQuery && (
                  <>
                    <div className="w-full h-full py-[20px] flexmm font-bold text-black/70">
                      <p>No Data Found</p>
                    </div>
                  </>
                )}
              {searchQuery && searchType === "Everything" && (
                <p className="text-[#C6C6C6] mb-[10px]">
                  {filteredCategories.length +
                    filteredArticles.length +
                    filteredPlaylist.length}{" "}
                  {filteredCategories.length +
                    filteredArticles.length +
                    filteredPlaylist.length >
                  1
                    ? "results"
                    : "result"}
                </p>
              )}
              <div
                className={`${
                  filteredArticles.length > 0 ? "block" : "hidden"
                } w-full cflexss`}
              >
                {searchQuery &&
                  (searchType === "Articles" || searchType === "Everything") &&
                  filteredArticles.length > 0 &&
                  !loading && (
                    <>
                      {searchType === "Articles" && (
                        <p className="text-[#C6C6C6] mb-[10px]">
                          {filteredArticles.length}{" "}
                          {filteredArticles.length > 1 ? "results" : "result"}
                        </p>
                      )}
                      <div className="w-full cflexss">
                        {filteredArticles.map((item, index) => {
                          return (
                            <>
                              {item.status === "published" && (
                                <div
                                  className="w-full cflexss gap-[5px] font-medium text-[14px] border-b-[1px] cursor-pointer py-[11px] hover:bg-gray-200"
                                  key={index}
                                  onClick={() => {
                                    navigate(
                                      `/article/${item.title.replace(
                                        / /g,
                                        "-"
                                      )}`
                                    );
                                    setSearch(false);
                                  }}
                                >
                                  <p className="text-[16px] text-black">
                                    {item.title}
                                  </p>
                                  <p className="text-[#2C2B2B] line-clamp-1">
                                    {item.description}
                                  </p>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
              </div>

              <div
                className={`${
                  filteredCategories.length > 0 ? "block" : "hidden"
                } w-full cflexss gap-[7px]`}
              >
                {searchQuery &&
                  (searchType === "Folders" || searchType === "Everything") &&
                  filteredCategories.length > 0 &&
                  !loading && (
                    <>
                      {searchType === "Folders" && (
                        <p className="text-[#C6C6C6] mb-[10px]">
                          {filteredCategories.length}{" "}
                          {filteredCategories.length > 1 ? "results" : "result"}
                        </p>
                      )}
                      <div className="w-full cflexss">
                        {filteredCategories.map((item, index) => {
                          return (
                            <>
                              {item.status === "published" && (
                                <div
                                  className="w-full cflexss gap-[5px] font-medium text-[14px] border-b-[1px] cursor-pointer py-[11px] hover:bg-gray-200"
                                  key={index}
                                  onClick={() => {
                                    navigate(
                                      `/category/${item.name.replace(
                                        / /g,
                                        "-"
                                      )}`
                                    );
                                    setSearch(false);
                                  }}
                                >
                                  <p className="text-[16px] text-black">
                                    {item.name}
                                  </p>
                                  <p className="text-[#2C2B2B] line-clamp-1">
                                    {item.description}
                                  </p>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
              </div>

              <div
                className={`${
                  filteredPlaylist.length > 0 ? "block" : "hidden"
                } w-full cflexss gap-[7px]`}
              >
                {searchQuery &&
                  (searchType === "Videos" || searchType === "Everything") &&
                  filteredPlaylist.length > 0 &&
                  !loading && (
                    <>
                      {searchType === "Videos" && (
                        <p className="text-[#C6C6C6] mb-[10px]">
                          {filteredPlaylist.length}{" "}
                          {filteredPlaylist.length > 1 ? "results" : "result"}
                        </p>
                      )}
                      <div className="w-full cflexss">
                        {filteredPlaylist.map((item, index) => {
                          return (
                            <>
                              {item.status === "published" && (
                                <div
                                  className="w-full cflexss gap-[5px] font-medium text-[14px] border-b-[1px] cursor-pointer py-[11px] hover:bg-gray-200"
                                  key={index}
                                  onClick={() => {
                                    navigate(
                                      `/guide/${item.name.replace(/ /g, "-")}`
                                    );
                                    setSearch(false);
                                  }}
                                >
                                  <p className="text-[16px] text-black">
                                    {item.name}
                                  </p>
                                  <p className="text-[#2C2B2B] line-clamp-1">
                                    {item.description}
                                  </p>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div className="w-full flexsm border-t-[2px]">
              <button className="outline-none border-none flexmm" type="submit">
                {loading ? (
                  <Loader2 />
                ) : (
                  <Search
                    size="20px"
                    color="#C6C6C6"
                    className="cursor-pointer"
                    onClick={getSearched}
                  />
                )}
              </button>
              <input
                ref={searchRef}
                type="search"
                placeholder="Search for Collections, Articles and Videos"
                className="w-full outline-none p-[16px]"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SearchModal;
