import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import { X } from "heroicons-react";
import { submitRequest } from "../services/request";

const SubmitRequest = ({ setMakeRequest, designInfo, domainName }) => {
  const [changing, setChanging] = useState(false);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState("");
  const [requestBody, setRequestBody] = useState({
    type: "",
    email: "",
    subject: "",
    message: "",
    file: "",
  });

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "request") {
      if (value === "What do you need help with?") {
        setRequestBody({ ...requestBody, type: "" });
      } else if (value === "I want to report an issue") {
        setRequestBody({ ...requestBody, type: "Bugs" });
      } else if (value === "I have a question") {
        setRequestBody({ ...requestBody, type: "Questions" });
      } else if (value === "I have a feature suggestion") {
        setRequestBody({ ...requestBody, type: "Features" });
      }
      setRequest(value);
    } else {
      setRequestBody({ ...requestBody, [name]: value });
    }
    setChanging(!changing);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (valid) {
      setLoading(true);
      console.log(requestBody);
      await submitRequest(requestBody,domainName);
      // await submitRequest(requestBody, "tech_hub.tryalps.app");
      setLoading(false);
      setMakeRequest(false);
    }
  };

  useEffect(() => {
    if (
      requestBody.type &&
      requestBody.email &&
      requestBody.subject &&
      requestBody.message
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [changing]);

  return (
    <>
      <div
        className="fixed top-0 left-0 z-[300] w-full h-[100vh] p-[20px] bg-black/30 flexmm overflow-y-auto"
        style={{
          fontFamily: designInfo?.headingFont,
        }}
      >
        <div
          className="w-[730px] p-[20px] rounded-[6px] flexmm bg-white flex-shrink"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <form className="cflexss gap-[32px] w-full" onSubmit={handleSubmit}>
            <div className="cflexss gap-[22px] w-full">
              <div className="w-full flexbm font-semibold text-[18px] text-[#36383A]">
                <p>Submit a request</p>
                <X
                  size="20px"
                  className="cursor-pointer"
                  onClick={() => {
                    setMakeRequest(false);
                  }}
                />
              </div>
              <div className="w-full cflexss gap-[14px] font-normal text-[14px] text-[#656667]">
                <p>Fields marked with asterisk (*) are required.</p>
                <select
                  className="w-full rounded-[4px] border-[1px] border-b-black px-[12px] py-[10px] cursor-pointer text-black outline-none"
                  name="request"
                  value={request}
                  onChange={handleChange}
                  required
                >
                  <option>What do you need help with?</option>
                  <option>I want to report an issue</option>
                  <option>I have a question</option>
                  <option>I have a feature suggestion</option>
                </select>
                <input
                  type="email"
                  name="email"
                  value={requestBody.email}
                  required
                  placeholder="Your email address *"
                  onChange={handleChange}
                  className="w-full rounded-[4px] border-[1px] border-b-gray-400 px-[12px] py-[10px] text-black outline-none"
                />
                <input
                  type="text"
                  name="subject"
                  value={requestBody.subject}
                  required
                  placeholder="Subject *"
                  onChange={handleChange}
                  className="w-full rounded-[4px] border-[1px] border-b-gray-400 px-[12px] py-[10px] text-black outline-none"
                />
                <div className="w-full cflexss gap-[4px]">
                  <input
                    type="text"
                    name="message"
                    value={requestBody.message}
                    required
                    placeholder={
                      requestBody.type === "Features"
                        ? "How could this improvement help you? *"
                        : "Message *"
                    }
                    onChange={handleChange}
                    className="w-full rounded-[4px] border-[1px] border-b-gray-400 px-[12px] py-[10px] text-black outline-none"
                  />
                  {requestBody.type === "Features" ? (
                    <p className="font-normal text-[12px] text-[#292929]">
                      The more context we have, the better decisions we can
                      make. Please help us understand the potential of your
                      suggestion.
                    </p>
                  ) : (
                    <p className="font-normal text-[12px] text-[#292929]">
                      Please enter the details of your request. A member of our
                      support staff will respond as soon as possible.
                    </p>
                  )}
                </div>
                <div className="w-full rounded-[11px] bg-[#007AFF]/5 text-[#383838] border-[#007AFF] cflexmm gap-[10px] py-[12px] font-medium text-[14px] border-dashed border-[2px] cursor-pointer">
                  <p>Choose a file or drag and drop here</p>
                  <p className="font-normal text-[12px] text-[#717171]">
                    (max, 10MB)
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flexem">
              <button
                className={`${
                  valid
                    ? "bg-[#1F2023] cursor-pointer"
                    : "bg-gray-400 cursor-not-allowed"
                } rounded-[8px] font-medium text-[14px] text-white outline-none border-[#4D4E51] border-[1px] flexmm px-[16px] py-[10px]`}
                type="submit"
              >
                {loading ? (
                  <div className="flexmm gap-[10px]">
                    <Loader />
                    <p>Submit</p>
                  </div>
                ) : (
                  <p>Submit</p>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SubmitRequest;
