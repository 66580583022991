import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "heroicons-react";
import {
  getStatusArticles,
  getUserCategories,
  getCategoryArticles,
  upVoteArticle,
  downVoteArticle,
} from "../services/request";
import { SpinnerCircular } from "spinners-react";
import Markdown from "markdown-to-jsx";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
const ArticlePage = () => {
  const navigate = useNavigate();
  const markdownRef = useRef(null);
  const { articleId } = useParams();
  const top = useRef(null);
  const [
    domainName,
    current,
    setCurrent,
    content,
    setContent,
    view,
    guides,
    collections,
    searchQuery,
    setSearchQuery,
  ] = useOutletContext();
  const [articles, setArticles] = useState("");
  const [article, setArticle] = useState("");
  const [loading, setLoading] = useState(true);
  const [helpful, setHelpful] = useState(false);
  const [unHelpful, setUnHelpful] = useState(false);
  const [likes, setLikes] = useState(0);
  const [disLikes, setDisLikes] = useState(0);
  const [size, setSize] = useState(window.innerWidth);
  const [category, setCategory] = useState("");
  const [post, setPost] = useState(
    '# hello<br>\n\n\n\n\n\n\n\n![](http://res.cloudinary.com/ddkikp6ne/image/upload/v1715715028/articles/images/q7woma43yukv0h1cas1d.jpg "block image")\n\n'
  );

  const setUpVote = async (articleId) => {
    if (!helpful) {
      let data = await upVoteArticle(articleId, domainName);
      // let data = await upVoteArticle(articleId, "tech_hub.tryalps.app");
      setLikes(data.upVoteCount);
      setHelpful(true);
      setUnHelpful(true);
      console.log(data);
    }
  };

  const setDownVote = async (articleId) => {
    if (!unHelpful) {
      let data = await downVoteArticle(articleId, domainName);
      // let data = await downVoteArticle(articleId, "tech_hub.tryalps.app");
      setDisLikes(data.downVoteCount);
      setUnHelpful(true);
      setHelpful(true);
      console.log(data);
    }
  };

  const getArticle = async (categoryData) => {
    setLoading(true);
    let data = await getStatusArticles("published", domainName);
    // let data = await getStatusArticles("published", "tech_hub.tryalps.app");
    if (data) {
      console.log(data);
      const articleData = data.find(
        (item) => item.title === articleId.replace(/-/g, " ")
      );
      setContent(articleData);
      console.log(articleData);
      setArticle(articleData);
      setLikes(articleData.upVoteCount);
      setDisLikes(articleData.downVoteCount);
      getCategory(articleData.category);
    }
  };

  const getCategory = async (categoryId) => {
    scrollToRef(top);
    setLoading(true);
    let data = await getUserCategories(domainName);
    // let data = await getUserCategories("tech_hub.tryalps.app");
    if (data) {
      const categoryData = data.find((item) => item.id === categoryId);
      console.log(categoryData);
      setCategory(categoryData);
      getLinkedArticles(categoryId);
    }
  };

  const getLinkedArticles = async (categoryId) => {
    let data = await getCategoryArticles(categoryId, domainName);
    // let data = await getCategoryArticles(categoryId, "tech_hub.tryalps.app");
    let filteredData = data.filter(
      (item) =>
        item.status === "published" &&
        item.title !== articleId.replace(/-/g, " ")
    );
    console.log(filteredData);
    setArticles(filteredData);
    setLoading(false);
  };

  const doSomeMagic = () => {
    // console.log("Magic is happening");
    if (!loading) {
      setTimeout(() => {
        const images = !loading && markdownRef.current.querySelectorAll("img");
        images.forEach((image) => {
          image.style.width = "80%";
          image.style.height = "auto";
          image.style.borderRadius = "15px";
        });
        const headers =
          !loading &&
          markdownRef.current.querySelectorAll("h1, h2, h3, h4, h5, h6");
        headers.forEach((header) => {
          switch (header.tagName) {
            case "H1":
              header.style.fontSize = "2.2rem";
              header.style.fontWeight = "bold";
              break;
            case "H2":
              header.style.fontSize = "1.8rem";
              header.style.fontWeight = "bold";
              break;
            case "H3":
              header.style.fontSize = "1.5rem";
              header.style.fontWeight = "semibold";
              break;
            case "H4":
              header.style.fontSize = "1.2r5em";
              header.style.fontWeight = "normal";
              break;
            case "H5":
              header.style.fontSize = "1rem";
              header.style.fontWeight = "normal";
              break;
            case "H6":
              header.style.fontSize = "0.875rem";
              header.style.fontWeight = "normal";
              break;
            default:
              header.style.fontSize = "14px";
              header.style.fontWeight = "normal";
          }
        });
      }, 1200);
    }
  };

  doSomeMagic();

  useEffect(() => {
    // setSearchQuery("");
    getArticle();
    doSomeMagic();
  }, [articleId]);

  useEffect(() => {
    function handleResize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);
  return (
    <>
      <div
        className="ml-[305px] md:ml-0 w-full cflexss gap-[32px] mb-[100px]"
        ref={top}
      >
        {loading ? (
          <>
            <div className="w-full flexmm">
              <SpinnerCircular
                color="#000000"
                className="mr-4"
                secondaryColor={"#eeeeee"}
                size={50}
                thickness={150}
              />
            </div>
          </>
        ) : (
          <>
            <div className="w-full py-[4px] flexsm text-[14px]">
              <p
                className="text-gray-500 cursor-pointer"
                onClick={() => {
                  navigate("/");
                }}
              >
                Collections
              </p>
              <ChevronRight size="20px" color="gray" />
              <p>{category.name}</p>
              <ChevronRight size="20px" color="gray" />
              <p>{article.title}</p>
            </div>
            <p className="text-[40px] font-bold">{article.title}</p>
            <div className="w-full cflexss gap-[25px] text-[14px]">
              <img
                src={article.coverURL}
                className="rounded-[8px] w-full h-[500px] md1:h-[276px] object-cover"
                alt="category-cover"
              />
              <div className="cflexss gap-[8px]">
                <p className="font-semibold text-[#A3A3A3]">IN THIS ARTICLE</p>
                <p className="text-[18px]">{article.description}</p>
              </div>
            </div>
            <div ref={markdownRef} className="flexbs gap-[48px] w-full">
              <Markdown>{article["articleBody"]}</Markdown>
              <div className="cflexss gap-[8px] max-w-[200px] text-[16px]">
                <p className="font-semibold text-[18px]">In this article</p>
                <div className="w-full cflexss gap-[16px] border-l-[2px] text-[#4D4E51] px-[10px]">
                  <p>Table of Contents</p>
                  <p>Table of Contents</p>
                </div>
              </div>
            </div>
            <div className="py-[56px] w-full cflexmm gap-[16px] font-semibold text-[24px] text-[#36383A]">
              <p>Was this article helpful?</p>
              <div className="w-full flexmm gap-[15px]">
                <div
                  className={`cursor-pointer cflexmm px-[10px] text-[16px] rounded-[5px]`}
                  onClick={() => {
                    setUpVote(article.id);
                  }}
                >
                  <img
                    src="/likes.png"
                    alt="like-button"
                    width="40px"
                    height="40px"
                  />
                  <p>{likes}</p>
                </div>
                <div
                  className={`cursor-pointer cflexmm px-[10px] text-[16px] rounded-[5px]`}
                  onClick={() => {
                    setDownVote(article.id);
                  }}
                >
                  <img
                    src="/dislikes.png"
                    alt="dislike-button"
                    width="40px"
                    height="40px"
                  />
                  <p>{disLikes}</p>
                </div>
              </div>
            </div>
            <div className="w-full cflexss gap-[24px]">
              <p className="text-[32px] font-bold">Related Articles</p>
              <div className="w-full flexss gap-[32px] overflow-x-auto">
                {loading ? (
                  <div className="flexmm w-full">
                    <SpinnerCircular
                      color="#000000"
                      className="mr-4"
                      secondaryColor={"#eeeeee"}
                      size={50}
                      thickness={150}
                    />
                  </div>
                ) : (
                  <>
                    {articles.map((item, index) => {
                      return (
                        <>
                          <div
                            className="w-[372px] cflexss gap-[32px] hover:bg-[#f5f5f5] rounded-[8px] cursor-pointer py-[4px] md1:mb-[20px] md1:px-[4px] md1:flex-wrap"
                            onClick={() => {
                              navigate(
                                `/article/${item.title.replace(/ /g, "-")}`
                              );
                            }}
                            key={item.id}
                          >
                            <img
                              src={item.coverURL}
                              alt="article-image"
                              className="rounded-[8px] min-w-[300px] max-w-[300px] h-[200px] object-cover md1:w-full md1:max-w-full"
                            />
                            <div className="h-full cflexss gap-[8px] md1:w-full">
                              <p className="font-bold text-[20px]">
                                {item.title}
                              </p>
                              <p className="text-[16px] line-clamp-5">
                                {item.description}
                              </p>
                              <div className="flexss gap-[8px] text-[14px] text-[#A3A3A3]">
                                {/* <BookOpenOutline size="20px" /> */}
                                <p>3 min read</p>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ArticlePage;
