import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import CollectionCard from "./CollectionCard";

const Default = ({ designInfo }) => {
  const [
    domainName,
    current,
    setCurrent,
    content,
    setContent,
    view,
    articles,
    guides,
    collections,
    searchQuery,
    setSearchQuery,
  ] = useOutletContext();
  const [size, setSize] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);
  return (
    <>
      <div
        className="ml-[305px] md:ml-0 mt-[20px] md:mt-[40px] w-full h-full cflexss gap-[150px]"
        style={{
          width: size <= 800 ? "100%" : "calc(100% - 305px)",
          fontFamily: designInfo?.textFont,
        }}
      >
        {searchQuery && articles.length > 0 && (
          <div className="w-full h-full cflexss gap-[50px]">
            <div className="w-full flexbm md:flex-wrap md:gap-[15px]">
              <div className="cflexss gap-[8px]">
                <p className="text-[36px] sm:text-[30px] font-bold">Articles</p>
                <p className="text-[16px] text-[#666666]">
                  Level up your docs and your skills with our libary of articles
                </p>
              </div>
            </div>
            <div className="w-full flexss gap-[20px] flex-wrap">
              {articles?.map((item, index) => {
                return (
                  <>
                    {index + 1 < 7 && (
                      <CollectionCard
                        item={item}
                        type="article"
                        domainName={domainName}
                        setContent={setContent}
                        designInfo={designInfo}
                        setCurrent={setCurrent}
                        setSearchQuery={setSearchQuery}
                        key={item.id}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}
        {(view === "all" || view === "collections") &&
          collections.length > 0 && (
            <div className="w-full h-full cflexss gap-[50px]">
              <div className="w-full flexbm md:flex-wrap md:gap-[15px]">
                <div className="cflexss gap-[8px]">
                  <p className="text-[36px] sm:text-[30px] font-bold">
                    Articles
                  </p>
                  <p className="text-[16px] text-[#666666]">
                    Level up your docs and your skills with our libary of articles
                  </p>
                </div>
                <button className="px-[17px] py-[8px] rounded-[10px] border-[2px] text-[16px] font-semibold">
                  <p>See more</p>
                </button>
              </div>
              <div className="w-full flexss gap-[20px] flex-wrap">
                {collections?.map((item, index) => {
                  return (
                    <>
                      {index + 1 < 7 && (
                        <CollectionCard
                          item={item}
                          type="collection"
                          domainName={domainName}
                          setContent={setContent}
                          designInfo={designInfo}
                          setCurrent={setCurrent}
                          setSearchQuery={setSearchQuery}
                          key={item.id}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          )}

        {(view === "all" || view === "guides") && guides.length > 0 && (
          <div className="w-full h-full cflexss gap-[50px]">
            <div className="w-full flexbm md:flex-wrap md:gap-[15px]">
              <div className="cflexss gap-[8px]">
                <p className="text-[36px] font-bold sm:text-[30px]">Videos</p>
                <p className="text-[16px] text-[#666666]">
                  Level up your docs and your skills with our libary of videos
                </p>
              </div>
              <button className="px-[17px] py-[8px] rounded-[10px] border-[2px] text-[16px] font-semibold">
                <p>See more</p>
              </button>
            </div>
            <div className="w-full flexss gap-[20px] flex-wrap">
              {guides?.map((item, index) => {
                return (
                  <>
                    {index + 1 < 7 && (
                      <CollectionCard
                        item={item}
                        type="guide"
                        domainName={domainName}
                        setContent={setContent}
                        designInfo={designInfo}
                        setCurrent={setCurrent}
                        setSearchQuery={setSearchQuery}
                        key={item.id}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Default;
