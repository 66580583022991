import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MenuAlt3, X } from "heroicons-react";
import SubmitRequest from "../components/SubmitRequest";

const Header = ({ designInfo, domainName }) => {
  const [show, setShow] = useState(false);
  const [makeRequest, setMakeRequest] = useState(false);
  const navgate = useNavigate();
  return (
    <>
      {makeRequest && (
        <SubmitRequest          
          setMakeRequest={setMakeRequest}
          designInfo={designInfo}
          domainName={domainName}
        />
      )}
      <div
        className={`fixed hidden md:block ml-[20px] bg-white ${
          show && "h-[70vh]"
        } border-[2px] z-[250] w-[80vw] p-[20px] rounded-[10px] shadow-md`}
        style={{
          marginTop: show ? "70px" : "-100%",
          fontFamily: designInfo?.headingFont,
          transition: "0.5s",
        }}
      >
        <div
          className="cflexss gap-[16px] text-[14px]"
          style={{ color: designInfo?.brandColour }}
        >
          {designInfo?.headerLinks && (
            <>
              {designInfo?.headerLinks.map((link, index) => (
                <Link to={`${link.link}`}>
                  <p className="cursor-pointer" key={index}>
                    {link.name}
                  </p>
                </Link>
              ))}
            </>
          )}
          {/* <Link to={`${designInfo?.headerContactPageLink}`}> */}
          <button
            className="hover:bg-buttonHover rounded-[10px] font-inter px-[16px] py-[8px] flexmm font-[500] text-[14px] outline-none"
            style={{
              color: designInfo?.textColour,
              background: designInfo?.brandColour,
              fontFamily: designInfo?.headingFont,
            }}
            onClick={() => {
              setShow(false);
              setMakeRequest(true);
            }}
          >
            <p>Submit request</p>
          </button>
          {/* </Link> */}
        </div>
      </div>
      <div
        className="fixed top-0 left-0 w-[100vw] z-[200] px-[56px] sm:px-[20px] py-[12px] flexbm font-inter bg-white"
        style={{ fontFamily: designInfo?.headingFont }}
      >
        <div
          className="flexmm gap-[10px] text-[16px] font-medium cursor-pointer"
          onClick={() => {
            navgate("/");
          }}
        >
          <img
            src={
              designInfo?.headerLogo
                ? designInfo.headerLogo.base64
                : "/logo.svg"
            }
            alt="alps-logo"
            className="w-auto h-[32px] object-oontain"
          />
        </div>
        <div
          className="block md:hidden flexmm gap-[16px] text-[14px]"
          style={{ color: designInfo?.brandColour }}
        >
          {designInfo?.headerLinks && (
            <>
              {designInfo?.headerLinks.map((link, index) => (
                <Link to={`${link.link}`}>
                  <p className="cursor-pointer" key={index}>
                    {link.name}
                  </p>
                </Link>
              ))}
            </>
          )}
          {/* <Link to={`${designInfo?.headerContactPageLink}`}> */}
          <button
            className="hover:bg-buttonHover rounded-[10px] font-inter px-[16px] py-[8px] flexmm font-[500] text-[14px] outline-none"
            style={{
              color: designInfo?.textColour,
              background: designInfo?.brandColour,
              fontFamily: designInfo?.headingFont,
            }}
            onClick={() => {
              setMakeRequest(true);
            }}
          >
            <p>Submit request</p>
          </button>
          {/* </Link> */}
        </div>
        <div
          className="hidden md:block cursor-pointer"
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? <X size="25px" /> : <MenuAlt3 size="30px" />}
        </div>
      </div>
    </>
  );
};

export default Header;
