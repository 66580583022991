import React, { useState, useEffect } from "react";
import { ArrowRight } from "heroicons-react";
import { getCategoryArticles, getPlaylistGuides } from "../services/request";
import { useNavigate } from "react-router-dom";

const CollectionCard = ({
  item,
  type,
  domainName,
  designInfo,
  setContent,
  setCurrent,
  setSearchQuery,
}) => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [guideVideos, setGuideVideos] = useState([]);
  const getLinkedArticles = async () => {
    let data = await getCategoryArticles(item.id, domainName);
    // let data = await getCategoryArticles(item.id, "tech_hub.tryalps.app");
    let filteredData = data.filter((item) => item.status === "published");
    setArticles(filteredData);
  };
  const getVideos = async () => {
    let data = await getPlaylistGuides(item.id, domainName);
    // let data = await getPlaylistGuides(item.id, "tech_hub.tryalps.app");
    setGuideVideos(data);
  };

  function formatDuration(duration) {
    if (duration) {
      const match = duration.match(/(\d+)M(\d+)S/);
      if (!match) return null;
      else {
        const minutes = parseInt(match[1]);
        const seconds = parseInt(match[2]);

        return `${minutes}:${seconds}`;
      }
    }
  }

  useEffect(() => {
    if (item.id) {
      if (type === "collection") {
        getLinkedArticles();
      } else if (type === "guide") {
        getVideos();
      }
    }
  }, [item.id]);
  return (
    <>
      <div
        className={`${
          type === "collection" ? "w-[280px] h-[470px] md:w-full" : "w-[460px]"
        } cflexss gap-[24px] flex-shrink font-inter`}
        style={{ fontFamily: designInfo?.textFont }}
      >
        <div className="w-full cflexss gap-[20px] text-[16px] text-[#666666]">
          {type === "article" && (
            <>
              {" "}
              <div
                className="w-full h-[258px] flexmm cursor-pointer"
                onClick={() => {
                  setSearchQuery("");
                  setContent(item);
                  navigate(`/article/${item.title.replace(/ /g, "-")}`);
                }}
              >
                <img
                  src={item.coverURL}
                  alt=""
                  className="w-full h-full object-cover rounded-[8px]"
                />
              </div>
            </>
          )}
          {type === "collection" && (
            <>
              {" "}
              <div
                className="w-full h-[258px] flexmm cursor-pointer"
                onClick={() => {
                  setSearchQuery("");
                  setContent(item);
                  navigate(`/category/${item.name.replace(/ /g, "-")}`);
                }}
              >
                <img
                  src={item.coverURL}
                  alt=""
                  className="w-full h-full object-cover rounded-[8px]"
                />
              </div>
            </>
          )}
          {type == "guide" && (
            <>
              <div
                className="relative w-full h-[258px] flexmm cursor-pointer"
                onClick={() => {
                  setSearchQuery("");
                  setContent(item);
                  navigate(`/guide/${item.name.replace(/ /g, "-")}`);
                }}
              >
                <img
                  src={item.coverURL || guideVideos[0]?.thumbnail}
                  alt=""
                  className="w-full h-full object-cover rounded-[8px]"
                />
                <div className="absolute top-[100px] left-[200px] flexmm w-[58px] h-[58px] cursor-pointer rounded-full bg-[#000000]/90 border-[2px] border-white">
                  <img src="/play.svg" className="w-[20px]" alt="play-logo" />
                </div>
              </div>
            </>
          )}
          {type === "article" ? (
            <p className="text-[20px] font-bold text-[#000000]">{item.title}</p>
          ) : (
            <p className="text-[20px] font-bold text-[#000000]">{item.name}</p>
          )}
          {type === "article" && (
            <>
              <div className="w-full cflexss mt-[-15px] gap-[8px] line-clamp-3">
                {item.description}
              </div>
            </>
          )}

          {type === "collection" && (
            <>
              <div className="w-full cflexss mt-[-15px] gap-[8px]">
                {articles?.map((item, index) => {
                  if (index < 2) {
                    return (
                      <p
                        key={index}
                        className="underline line-clamp-1 cursor-pointer"
                        onClick={() => {
                          setCurrent(item);
                          navigate(`/article/${item.title.replace(/ /g, "-")}`);
                        }}
                      >
                        {item.title}
                      </p>
                    );
                  }
                })}
                {articles.length > 2 && (
                  <p>{`${articles.length - 2} ${
                    articles.length - 2 > 1 ? "more articles" : "more article"
                  }`}</p>
                )}
                {articles.length > 2 && (
                  <button
                    className="px-[16px] py-[10px] rounded-[8px] gap-[8px] mt-[16px] flexmm border-[2px] text-mainColor text-[14px] font-medium"
                    onClick={() => {
                      navigate(`/category/${item.name.replace(/ /g, "-")}`);
                    }}
                  >
                    <p>See more</p>
                    <ArrowRight size="20px" />
                  </button>
                )}
              </div>
            </>
          )}

          {type === "guide" && (
            <>
              <div className="w-full cflexss mt-[-15px]">
                <p className="text-[12px] mb-[10px] text-[#000000]/50">
                  {guideVideos.length}{" "}
                  {guideVideos.length > 1 ? "lessons" : "lesson"}
                </p>
                {guideVideos?.map((item, index) => {
                  if (index < 3) {
                    return (
                      <div
                        key={index}
                        className="w-full py-[12px] text-[14px] text-[#121212] flexbm line-clamp-1 border-t-[2px] cursor-pointer"
                        onClick={() => {
                          setContent(item);
                          setCurrent(item);
                          navigate(`/guide/${item.name.replace(/ /g, "-")}`);
                        }}
                      >
                        <p className="font-semibold truncate max-w-[25ch] ...">
                          {item.title}
                        </p>
                        <p className="text-[#121212]/50">
                          {formatDuration(item?.duration)}
                        </p>
                      </div>
                    );
                  }
                })}
                {guideVideos.length > 3 && (
                  <p className="w-full pt-[10px] text-[14px] border-t-[2px]">{`${
                    guideVideos.length - 3
                  } more...`}</p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CollectionCard;
