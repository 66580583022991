import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import Header from "../Pages/Header";
import Footer from "../Pages/Footer";
import { useNavigate } from "react-router-dom";
import {
  VideoCameraOutline,
  BookOpenOutline,
  ChevronDown,
  ChevronUp,
} from "heroicons-react";
import {
  getUserCategories,
  getUserPlaylist,
  getCategoryArticles,
  getPlaylistGuides,
  getUserProfile,
  getStatusArticles,
} from "../services/request";
import Loader2 from "../components/Loader2";
// import { Search } from "heroicons-react";

const RootLayout = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const { guideId } = useParams();
  const { articleId } = useParams();
  const [userInfo, setUserInfo] = useState("");
  const [view, setView] = useState("all");
  const [selectedC, setSelectedC] = useState("");
  const [selectedG, setSelectedG] = useState("");
  const [collectionArticles, setCollectionArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [guideVideos, setGuideVideos] = useState([]);
  const [collections, setCollections] = useState([]);
  const [guides, setGuides] = useState([]);
  const [articles, setArticles] = useState([]);
  const [fixedArticles, setFixedArticles] = useState([]);
  const [fixedCollections, setFixedCollections] = useState([]);
  const [fixedGuides, setFixedGuides] = useState([]);
  const [current, setCurrent] = useState("");
  const [loading, setLoading] = useState(false);
  const [ld, setLd] = useState(false);
  const [ld2, setLd2] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [size, setSize] = useState(window.innerWidth);
  const getDomainName = () => {
    const url = window.location;
    const href = new URL(url).href;
    const parts = href.split("/"); // Split the pathname by '/'
    const result = parts[2]; // Get the third part, which is "techhub.tryalps.app"
    const domain = result.split(".")[0]; // Split the result by '.', and get the first part, which is "techhub"
    console.log(result);
    return result; // Output: "techhub"
  };
  const [domainName, setDomainName] = useState(getDomainName().toString()); // [1
  const [content, setContent] = useState(""); // [2
  const getUser = async () => {
    console.log(getDomainName().toString());
    let info = await getUserProfile(getDomainName().toString());
    // let info = await getUserProfile("tech_hub.tryalps.app");
    setUserInfo({
      brandColour: info?.colours?.brandColour,
      textColour: info?.colours?.textColours,
      heroSectionHeadline: info?.heroSection?.headline,
      heroSectionBodyCopy: info?.heroSection?.bodyCopy,
      heroSectionBackground: info?.heroSection?.background,
      heroSectionTextColour: info?.heroSection?.textColour,
      headingFont: info?.fonts?.headingFont,
      textFont: info?.fonts?.textFont,
      headerLogo: { base64: info?.header?.logo },
      headerHomePageLink: info?.header?.homePageLink,
      headerContactPageLink: info?.header?.contactPageLink,
      headerLinks: info?.header?.headerLinks,
      footerContactUsCard: info?.footer.contactUsCard,
      footerAlpsLink: info?.footer.alpsLink,
    });
    console.log(info);
  };

  const getContents = async () => {
    setLoading(true);
    let data = await getUserCategories(domainName);
    // let data = await getUserCategories("tech_hub.tryalps.app");
    if (data) {
      let filteredData = data.filter((item) => item.status === "published");
      setCollections(filteredData);
      setFixedCollections(filteredData);
    }
    getPlaylist();
  };

  const getPlaylist = async () => {
    let data;
    data = await getUserPlaylist(domainName);
    // data = await getUserPlaylist("tech_hub.tryalps.app");
    if (data) {
      let filteredData = data.filter((item) => item.status === "published");
      setGuides(filteredData);
      setFixedGuides(filteredData);
    }
    getArticles();
  };

  const getArticles = async () => {
    let data = await getStatusArticles("published", domainName);
    // let data = await getStatusArticles("published", "tech_hub.tryalps.app");
    if (data) {
      setArticles(data);
      setFixedArticles(data);
    }
    setLoading(false);
  };

  const getLinkedArticles = async (id) => {
    setCollectionArticles([]);
    setLd(true);
    let data = await getCategoryArticles(id, domainName);
    // let data = await getCategoryArticles(id, "tech_hub.tryalps.app");
    setCollectionArticles(data);
    setLd(false);
  };

  const getVideos = async (id) => {
    setLd2(true);
    let data = await getPlaylistGuides(id, domainName);
    // let data = await getPlaylistGuides(id, "tech_hub.tryalps.app");
    setGuideVideos(data);
    setLd2(false);
  };

  useEffect(() => {
    getContents();
    console.log(domainName);
  }, [refresh]);

  useEffect(() => {
    function handleResize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [size]);

  useEffect(() => {
    let filteredArticles = fixedArticles.filter(
      (item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
        item.status === "published"
    );
    let filteredCollections = fixedCollections.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        item.status === "published"
    );
    let filteredGuides = fixedGuides.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        item.status === "published"
    );
    setArticles(filteredArticles);
    setCollections(filteredCollections);
    setGuides(filteredGuides);
  }, [searchQuery]);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <div className="relative cflexss w-full font-inter">
        <Header designInfo={userInfo} domainName={domainName}/>
        <div
          className={`w-full h-full flexbs px-[56px] sm:px-[20px] mt-[30px] ${
            !categoryId && !guideId && !articleId
              ? "md:mt-[70px]"
              : "md:mt-[30px]"
          } font-inter py-[51px] gap-[24px] bg-white`}
          style={{ fontFamily: userInfo?.textFont }}
        >
          <div
            className={`block md:hidden w-[280px] ${
              !categoryId && !guideId && !articleId
                ? "absolute top-[480px]"
                : "fixed top-[80px]"
            } cflexss pb-[100px] gap-[32px] pr-[11px] text-[14px] overflow-y-auto`}
            style={{
              height:
                !categoryId && !guideId && !articleId
                  ? "auto"
                  : "calc(100vh - 80px)",
            }}
          >
            {/* {!categoryId && !guideId && !articleId && (
              <div className="flexmm w-[253px] border-[2px] rounded-[6px] px-[12px] py-[8px] gap-[12px]">
                <Search size="20px" color="gray" />
                <input
                  className="outline-none border-none w-full"
                  placeholder="Search"
                />
              </div>
            )} */}
            {collections.length > 0 && guides.length > 0 && (
              <div className="cflexss gap-[12px]">
                <div
                  className="font-semibold flexsm gap-[4px] cursor-pointer"
                  onClick={() => {
                    if (view === "collections") {
                      setView("all");
                    } else {
                      setView("collections");
                    }
                    setContent("");
                    navigate("/");
                  }}
                >
                  <BookOpenOutline size="20px" />
                  <p>Articles</p>
                </div>

                <div
                  className="font-semibold flexsm gap-[4px] cursor-pointer"
                  onClick={() => {
                    if (view === "guides") {
                      setView("all");
                    } else {
                      setView("guides");
                    }
                    setContent("");
                    navigate("/");
                  }}
                >
                  <VideoCameraOutline size="20px" />
                  <p>Videos</p>
                </div>
              </div>
            )}

            <div className="w-full cflexss gap-[2px]">
              {collections.length > 0 && (
                <div className="font-bold text-[16px] text-[#4D4E51] flexsm gap-[4px]">
                  <BookOpenOutline size="20px" />
                  <p>Articles</p>
                </div>
              )}
              {collections.length > 0 &&
                collections.map((item, index) => {
                  return (
                    <>
                      {item.status === "published" && (
                        <>
                          <div
                            key={item.id}
                            className={`w-full flexbm text-[16px] text-[#4D4E51] cursor-pointer px-[16px] py-[12px] ${
                              (selectedC === item.name || content === item) &&
                              "bg-[#f5f5f5] border-l-[2px] border-black"
                            } hover:bg-[#f5f5f5] font-bold`}
                            onClick={() => {
                              if (selectedC === item.name) {
                                setSelectedC("");
                              } else {
                                getLinkedArticles(item.id);
                                setSelectedC(item.name);
                              }
                              navigate(
                                `/category/${item.name.replace(/ /g, "-")}`
                              );
                              setContent(item);
                            }}
                          >
                            <p>{item.name}</p>
                            {selectedC === item.name || content === item ? (
                              <ChevronUp size="20px" />
                            ) : (
                              <ChevronDown size="20px" />
                            )}
                          </div>
                          <ul
                            key={index}
                            className="w-full cflexss gap-[7px] text-[14px] font-medium border-l-[2px] text-[#656667] ml-[10px] list-none"
                          >
                            {selectedC === item.name && (
                              <>
                                {ld ? (
                                  <Loader2 />
                                ) : (
                                  <>
                                    {collectionArticles.map(
                                      (article, index) => {
                                        return (
                                          <>
                                            {article.status === "published" && (
                                              <li
                                                className={`cursor-pointer line-clamp-1 w-full px-[12px] py-[13px] ${
                                                  current === article &&
                                                  "bg-[#f5f5f5] border-l-[2px] border-gray-400"
                                                } hover:bg-[#f5f5f5]`}
                                                key={article.id}
                                                onClick={() => {
                                                  setContent(item);
                                                  setCurrent(article);
                                                  navigate(
                                                    `/article/${article.title.replace(
                                                      / /g,
                                                      "-"
                                                    )}`
                                                  );
                                                }}
                                              >
                                                {article.title}
                                              </li>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </ul>
                        </>
                      )}
                    </>
                  );
                })}
            </div>
            <div className="w-full cflexss gap-[2px]">
              {guides.length > 0 && (
                <div className="font-bold text-[16px] text-[#4D4E51] flexsm gap-[4px]">
                  <VideoCameraOutline size="20px" />
                  <p>Videos</p>
                </div>
              )}
              {guides.length > 0 &&
                guides?.map((item, index) => {
                  return (
                    <>
                      {item.status === "published" && (
                        <>
                          <div
                            key={item.id}
                            className={`w-full flexbm text-[16px] text-[#4D4E51] cursor-pointer px-[16px] py-[12px] ${
                              (selectedG === item.name || content === item) &&
                              "bg-[#f5f5f5] border-l-[2px] border-black"
                            } hover:bg-[#f5f5f5] font-bold`}
                            onClick={() => {
                              if (selectedG === item.name) {
                                setSelectedG("");
                              } else {
                                getVideos(item.id);
                                setSelectedG(item.name);
                              }
                              navigate(
                                `/guide/${item.name.replace(/ /g, "-")}`
                              );
                              setCurrent("");
                              setContent(item);
                            }}
                          >
                            <p>{item.name}</p>
                            {selectedG === item.name || content === item ? (
                              <ChevronUp size="20px" />
                            ) : (
                              <ChevronDown size="20px" />
                            )}
                          </div>
                          <ul
                            key={index}
                            className="w-full cflexss gap-[7px] text-[14px] font-medium border-l-[2px] text-[#656667] ml-[10px] list-none"
                          >
                            {selectedG === item.name && (
                              <>
                                {ld2 ? (
                                  <Loader2 />
                                ) : (
                                  guideVideos.map((video, index) => {
                                    return (
                                      <li
                                        className={`cursor-pointer line-clamp-3 w-full px-[12px] py-[13px] ${
                                          video.id === current.id &&
                                          "bg-[#f5f5f5] border-l-[2px] border-gray-400"
                                        } hover:bg-[#f5f5f5]`}
                                        key={video.id}
                                        onClick={() => {
                                          navigate(
                                            `/guide/${item.name.replace(
                                              / /g,
                                              "-"
                                            )}`
                                          );
                                          setContent(item);
                                          setCurrent(video);
                                        }}
                                      >
                                        {video.title}
                                      </li>
                                    );
                                  })
                                )}
                              </>
                            )}
                          </ul>
                        </>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
          <main
            className="w-full"
            style={{
              width:
                size <= 800
                  ? "100%"
                  : (categoryId || guideId || articleId) &&
                    "calc(100% - 305px)",
              fontFamily: userInfo?.textFont,
            }}
          >
            <Outlet
              context={[
                domainName,
                current,
                setCurrent,
                content,
                setContent,
                view,
                articles,
                guides,
                collections,
                searchQuery,
                setSearchQuery,
              ]}
            />
          </main>
        </div>
        <Footer
          designInfo={userInfo}
          categoryId={categoryId}
          articleId={articleId}
          guideId={guideId}
        />
      </div>
    </>
  );
};

export default RootLayout;
