import React, { useEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import HeroSection from "./HeroSection";
import Body from "./Body";
import RootLayout from "../components/RootLayout";
import { getUserProfile } from "../services/request";

const HomePage = () => {
  const { Id } = useParams();
  const [
    domainName,
    current,
    setCurrent,
    content,
    setContent,
    view,
    articles,
    guides,
    collections,
    searchQuery,
    setSearchQuery,
  ] = useOutletContext();
  const [userInfo, setUserInfo] = useState("");
  const getUser = async () => {
    console.log(domainName);
    let info = await getUserProfile(domainName);
    // let info = await getUserProfile("tech_hub.tryalps.app");
    setUserInfo({
      brandColour: info?.colours?.brandColour,
      textColour: info?.colours?.textColours,
      heroSectionHeadline: info?.heroSection?.headline,
      heroSectionBodyCopy: info?.heroSection?.bodyCopy,
      heroSectionBackground: info?.heroSection?.background,
      heroSectionTextColour: info?.heroSection?.textColour,
      headingFont: info?.fonts?.headingFont,
      textFont: info?.fonts?.textFont,
      headerLogo: { base64: info?.header?.logo },
      headerHomePageLink: info?.header?.homePageLink,
      headerContactPageLink: info?.header?.contactPageLink,
      headerLinks: info?.header?.headerLinks,
      footerContactUsCard: info?.footer.contactUsCard,
      footerAlpsLink: info?.footer.alpsLink,
    });
    console.log(info);
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      {!Id && (
        <HeroSection
          designInfo={userInfo}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      )}
      <Body                     
        designInfo={userInfo}      
      />
    </>
  );
};

export default HomePage;
